<!-- =========================================================================================
    File Name: TheFooter.vue
    Description: Footer component
    Component Name: TheFooter
    ----------------------------------------------------------------------------------------
    Item Name: Vuesax Admin - VueJS Dashboard Admin Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <footer class="w-full the-footer text-center sm:text-left" :class="classes">
    <span
      >COPYRIGHT @ {{ new Date().getFullYear() }}
      <a href="https://www.recruitaguide.co.za" target="_blank" rel="nofollow">Recruit A Guide</a>. All rights Reserved</span
    >
  </footer>
</template>

<script>
export default {
  name: 'TheFooter',
  props: {
    classes: {
      type: String,
    },
  },
};
</script>

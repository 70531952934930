/*=========================================================================================
  File Name: sidebarItems.js
  Description: Sidebar Items list. Add / Remove menu items from here.
  Strucutre:
          url     => router path
          name    => name to display in sidebar
          slug    => router path name
          icon    => Feather Icon component/icon name
          tag     => text to display on badge
          tagColor  => class to apply on badge element
          i18n    => Internationalization
          submenu   => submenu of current item (current item will become dropdown )
                NOTE: Submenu don't have any icon(you can add icon if u want to display)
          isDisabled  => disable sidebar item/group
  ----------------------------------------------------------------------------------------
  Item Name: Vuesax Admin - VueJS Dashboard Admin Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

export default [
  {
    url: '/pages/dashboard',
    name: 'Dashboard',
    icon: 'HomeIcon',
    i18n: 'Profile',
  },

  {
    url: '/pages/jobs',
    name: 'Jobs',
    icon: 'BriefcaseIcon',
    i18n: 'Profile',
  },
  {
    url: '/pages/applications',
    name: 'Applications',
    icon: 'ListIcon',
    i18n: 'Profile',
  },

  {
    url: '/pages/resume',
    name: 'Guide Board',
    icon: 'FileIcon',
    i18n: 'Profile',
  },
  {
    url: '/pages/chat',
    name: 'Chat',
    slug: 'chat',
    icon: 'MessageSquareIcon',
    i18n: 'Chat',
  },
  // {
  //   url: '/pages/notifications',
  //   name: 'Notifications',
  //   slug: 'notifications',
  //   icon: 'BellIcon',
  //   i18n: 'Notifications',
  // },
  // {

  {
    url: '/pages/archived-jobs',
    name: 'Archived Jobs',
    icon: 'ArchiveIcon',
    i18n: 'ArchivedJobs',
  },
  {
    isDisabled: true,
  },
  {
    url: '/pages/profile',
    name: 'Profile',
    icon: 'UserIcon',
    i18n: 'Profile',
  },
  {
    url: '/pages/settings',
    name: 'Settings',
    icon: 'SettingsIcon',
    i18n: 'Settings',
  },
];

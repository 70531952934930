<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    class="w-6 h-6 flex items-center justify-items-center feather-icon"
    viewBox="0 0 24 24"
  >
    <g id="calendar" transform="translate(-2 -1)">
      <rect
        id="Rectangle_127"
        data-name="Rectangle 127"
        width="18"
        height="18"
        rx="2"
        transform="translate(3 4)"
        fill="none"
        stroke="rgba(184, 194, 204)"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
      />
      <line
        id="Line_18"
        data-name="Line 18"
        y2="4"
        transform="translate(16 2)"
        fill="none"
        stroke="rgba(184, 194, 204)"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
      />
      <line
        id="Line_19"
        data-name="Line 19"
        y2="4"
        transform="translate(8 2)"
        fill="none"
        stroke="rgba(184, 194, 204)"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
      />
      <line
        id="Line_20"
        data-name="Line 20"
        x2="18"
        transform="translate(3 10)"
        fill="none"
        stroke="rgba(184, 194, 204)"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
      />
      <line
        id="Line_21"
        data-name="Line 21"
        x2="11"
        transform="translate(6.5 13.5)"
        fill="none"
        stroke="rgba(184, 194, 204)"
        stroke-linecap="round"
        stroke-width="2"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'CalendarSVG',
};
</script>
